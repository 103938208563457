<template>
  <div class="LoginIn">
    <div class="top">
      <img src="../../assets/image/Quanzhangwologo.png" alt="" />
      <a-button>立即注册</a-button>
    </div>
    <div class="bottom">
      <div class="loginCard">
        <div class="card_tit">用户登录</div>
        <div class="card_form">
          <a-form-model ref="ruleForm" :model="ruleForm" :rules="rules">
            <a-form-model-item has-feedback prop="name">
              <a-input
                v-model="ruleForm.name"
                type="password"
                autocomplete="off"
                placeholder="用户名/手机号"
              />
            </a-form-model-item>
            <a-form-model-item has-feedback prop="pass">
              <a-input
                v-model="ruleForm.pass"
                type="password"
                autocomplete="off"
                placeholder="密码"
              />
            </a-form-model-item>

            <a-form-model-item>
              <a-button type="primary" @click="submitForm('ruleForm')">
                登录
              </a-button>
              <!-- <a-button
                style="margin-left: 10px"
                @click="resetForm('ruleForm')"
              >
                重置
              </a-button> -->
            </a-form-model-item>
          </a-form-model>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "LoginIn",
  data() {
    let checkPending;
    let checkAge = (rule, value, callback) => {
      clearTimeout(checkPending);
      //   if (!value) {
      //     return callback(new Error("Please input the age"));
      //   }
      checkPending = setTimeout(() => {
        if (!Number.isInteger(value)) {
          callback(new Error("Please input digits"));
        } else {
          if (value < 18) {
            callback(new Error("Age must be greater than 18"));
          } else {
            callback();
          }
        }
      }, 1000);
    };
    let validatePass = (rule, value, callback) => {
      if (value === "") {
        callback(new Error("Please input the password"));
      } else {
        if (this.ruleForm.name !== "") {
          this.$refs.ruleForm.validateField("name");
        }
        callback();
      }
    };
    // let validatePass2 = (rule, value, callback) => {
    //   if (value === "") {
    //     callback(new Error("Please input the password again"));
    //   } else if (value !== this.ruleForm.pass) {
    //     callback(new Error("Two inputs don't match!"));
    //   } else {
    //     callback();
    //   }
    // };
    return {
      ruleForm: {
        pass: "",
        name: "",
        // age: "",
      },
      rules: {
        pass: [{ validator: validatePass, trigger: "change" }],
        name: [
          {
            required: true,
            message: "请输入用户名/手机号",
            trigger: "blur",
          },
          {
            min: 2,
            max: 11,
            message: "长度大于1小于12",
            trigger: "blur",
          },
        ],
        // age: [{ validator: checkAge, trigger: "change" }],
      },
      //   layout: {
      //     labelCol: { span: 8 },
      //     wrapperCol: { span: 14 },
      //   },
    };
  },
  methods: {
    submitForm(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          //   alert("submit!");
          this.$router.push({
            path: "/Home",
          });
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
    resetForm(formName) {
      this.$refs[formName].resetFields();
    },
  },
};
</script>

<style scoped lang="less">
.LoginIn {
  height: 100%;
  width: 100%;
  min-width: 1180px;
}
.top {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 20px;
}
.bottom {
  background-image: url("../../assets/image/Rectangle 34624440.png");
  background-size: 100% 790px;
  width: 100%;
  min-height: 790px;
  //   height: auto;
  display: flex;
  justify-content: center;
  align-items: center;
}
.loginCard {
  width: 449px;
  height: 449px;
  background: #ffffff;
  text-align: center;
  .card_tit {
    // width: 96px;
    // height: 30px;
    font-family: Microsoft YaHei UI, Microsoft YaHei UI;
    font-weight: 400;
    font-size: 24px;
    color: #000000;
    line-height: 64px;
    text-align: center;
    font-style: normal;
    text-transform: none;
  }
  .card_form {
    // margin-left: 20%;
    display: flex;
    justify-content: center;
    // width: 269px;
    // /deep/.ant-form-item-children {
    //   width: 100% !important;
    // }
    .ant-btn {
      width: 100%;
    }
  }
}
</style>