<template>
  <div class="TechnicalServices">
    <div class="top">技术服务</div>
    <div class="inner inner0">AI应用开发</div>
    <div class="inner inner1">视频AI模型搭建</div>
    <div class="inner inner3">AI训练</div>
    <div class="inner inner4">小程序/app开发</div>
    <div class="inner inner5">服务器端开发</div>
    <div class="inner inner6">web端开发</div>
  </div>
</template>

<script>
export default {
  name: "TechnicalServices",
  data() {
    return {};
  },
};
</script>

<style scoped lang="less">
.TechnicalServices {
  width: 100%;
  height: 1100px;
  background-image: url("../assets/image/Mask group.png");
  background-size: 100% 1100px;
  background-repeat: no-repeat;
  background-color: pink;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
}
.top {
  position: absolute;
  font-family: Microsoft YaHei UI, Microsoft YaHei UI;
  font-weight: bold;
  font-size: 48px;
  color: #ffffff;
  line-height: 56px;
  text-align: center;
  font-style: normal;
  text-transform: none;
}
.inner {
  //   width: 160px;
  position: absolute;

  height: 271px;
  width: 314px;
  text-align: center;
  font-family: Microsoft YaHei UI, Microsoft YaHei UI;
  font-weight: bold;
  font-size: 30px;
  color: #ffffff;
  line-height: 271px;
  //   text-align: left;
  font-style: normal;
  text-transform: none;
  background-image: url("../assets/image/Polygon 7.png");
  //   margin-left: -500px;
}
.inner0 {
  margin-top: -600px;
}
.inner1 {
  margin-left: -550px;
  margin-top: -300px;
}
.inner3 {
  margin-left: 550px;
  margin-top: -300px;
}
.inner4 {
  margin-left: -550px;
  margin-top: 300px;
}
.inner5 {
  margin-left: 550px;
  margin-top: 300px;
}
.inner6 {
  margin-top: 600px;
}
</style>