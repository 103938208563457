<template>
  <div class="CompanyProfile">
    <div class="CompanyProfile_name">公司简介</div>
    <div class="Profile">
      <div class="CompanyProfile_Profile">
        全掌握科技依托人工智能、大数据、物联网技术为家装企业解决交付与营销痛点。
        全掌握家装信息化Al赋能系统以让装修企业做到用更少的人管更多的工地且让业主更满意为最终奋斗目标，人工智能的使用让交付业务不依赖于个别熟练工目业务可复制、可管可控，系统帮助管理者轻松直观掌控交付全过程，个人使用界面简单人性化，最终实现将管理者从日常繁杂的琐事中解放出来。系统以服务─线操作人员提高工作效率为立足点，以帮助装企占据业主心智提升业主体验感为努力方向。
      </div>
      <img src="../assets/image/Rectangle 135.png" alt="" />
    </div>
    <div class="WeAim_name">我们旨在</div>
    <div class="WeAim">
      <div class="WeAim_inner">
        <img src="../assets/image/Group 585.png" alt="" />
        <div class="little_tit">为装修企业带来价值</div>
        <div class="desc">
          <div>
            帮助传统装修企业进行数字化改造升级实现降本增效，应对激烈的市场竞争
          </div>
        </div>
      </div>
      <div class="WeAim_inner">
        <img src="../assets/image/Group 586.png" alt="" />
        <div class="little_tit">为装修信息化提供AI赋能</div>
        <div class="desc">
          <div>通过AI赋能使传统装修场景下机器人替人成为可能</div>
        </div>
      </div>
      <div class="WeAim_inner">
        <img src="../assets/image/Group 587.png" alt="" />
        <div class="little_tit">将管理理念嵌入业务流程</div>
        <div class="desc">
          <div>将管理理念、信息化系统、业务三者真正融为一体</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "CompanyProfile",
  components: {
    // HelloWorld,
  },
};
</script>

<style scoped lang="less">
.CompanyProfile {
}
.CompanyProfile_name {
  width: 100%;
  margin: 30px 0;
  display: flex;
  justify-content: center;
  height: 61px;
  font-family: Microsoft YaHei UI, Microsoft YaHei UI;
  font-weight: bold;
  font-size: 48px;
  color: #000000;
  line-height: 56px;
  text-align: left;
  font-style: normal;
  text-transform: none;
}
.Profile {
  display: flex;
  align-items: center;
  justify-content: center;
  img {
    margin-left: 100px;
  }
}
.CompanyProfile_Profile {
  width: 516px;
  height: 280px;
  font-family: Microsoft YaHei UI, Microsoft YaHei UI;
  font-weight: 400;
  font-size: 20px;
  color: #000000;
  line-height: 28px;
  text-align: left;
  font-style: normal;
  text-transform: none;
}

.WeAim_name {
  display: flex;
  justify-content: center;
  //   width: 128px;
  height: 41px;
  font-family: Microsoft YaHei UI, Microsoft YaHei UI;
  font-weight: bold;
  font-size: 32px;
  color: #2164d8;
  line-height: 38px;
  text-align: left;
  font-style: normal;
  text-transform: none;
  margin: 60px 0;
}
.WeAim {
  display: flex;
  justify-content: space-around;
  align-items: center;
  .WeAim_inner {
    text-align: center;
    width: 282px;
    height: 338px;
    background: #f9f9f9;
    img {
      margin-top: 50px;
    }
    .little_tit {
      text-align: center;
      width: 100%;
      //   background-color: pink;
      height: 25px;
      font-family: Microsoft YaHei UI, Microsoft YaHei UI;
      font-weight: 400;
      font-size: 20px;
      color: #000000;
      line-height: 23px;
      font-style: normal;
      text-transform: none;
      margin: 30px 0;
    }
    .desc {
      width: 100%;
      display: flex;
      justify-content: center;
      div {
        width: 60%;
        // width: 162px;
        // height: 92px;
        font-family: Microsoft YaHei UI, Microsoft YaHei UI;
        font-weight: 300;
        font-size: 16px;
        color: #000000;
        line-height: 21px;
        text-align: left;
        font-style: normal;
        text-transform: none;
      }
    }
  }
}
</style>