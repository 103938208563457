<template>
  <div>
    <div class="bottom">
      <div class="bottom_top">联系我们</div>
      <div class="bottom_flex">
        <!-- <div class="flex_left">
          <a-form-model :model="form">
            <a-form-model-item>
              <a-input v-model="form.fieldA" placeholder="姓名" />
            </a-form-model-item>
            <a-form-model-item>
              <a-input v-model="form.fieldB" placeholder="联系方式" />
            </a-form-model-item>
            <a-form-model-item>
              <a-textarea
                v-model="form.fieldC"
                placeholder="留言内容"
                :auto-size="{ minRows: 2, maxRows: 6 }"
              />
            </a-form-model-item>
            <a-form-model-item>
              <a-button type="primary" @click="handleFormLayoutChange">
                提交
              </a-button>
            </a-form-model-item>
          </a-form-model>
        </div> -->
        <div class="flex_right">
          <div>
            <img src="../assets/image/ApplyTtrial/map-pin.png" alt="" />
            北京市朝阳区酒仙桥东路1号院8号楼A厅四层
          </div>
          <div>
            <img src="../assets/image/ApplyTtrial/clock.png" alt="" />
            周一到周五9：00-18:00
          </div>
          <div>
            <img src="../assets/image/ApplyTtrial/tablet.png" alt="" />
            010-64360088 13810731902
          </div>
          <div>
            <!-- <img src="../assets/image/ApplyTtrial/mail.png" alt="" /> -->
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "ContactUs",
  data() {
    return {
      form: {
        layout: "horizontal",
        fieldA: "",
        fieldB: "",
        fieldC: "",
      },
    };
  },
  computed: {
    buttonItemLayout() {
      const { layout } = this.form;
      return layout === "horizontal"
        ? {
            wrapperCol: { span: 14, offset: 0 },
          }
        : {};
    },
  },
  methods: {
    handleFormLayoutChange(e) {
      console.log("handleFormLayoutChange", this.form);
    },
  },
};
</script>

<style scoped lang="less">
.bottom {
  width: 100%;
  height: 736px;
  //   height: 900px;
  //   margin-top: 178px;
  background-image: url("../assets/image/Rectangle 150.png");
  background-size: 100% 736px;
  .bottom_top {
    //     width: 192px;
    // height: 61px;
    font-family: Microsoft YaHei UI, Microsoft YaHei UI;
    font-weight: bold;
    font-size: 48px;
    color: #ffffff;
    line-height: 200px;
    text-align: center;
    font-style: normal;
    text-transform: none;
  }
  .bottom_flex {
    display: flex;
    justify-content: center;
  }
  .flex_left {
    text-align: left;
    button {
      background-color: green;
      border: 1px solid green;
    }
  }
  .flex_right {
    text-align: left;
    div {
      margin-top: 5px;
      margin-bottom: 20px;
      margin-left: 20px;

      //   width: 413px;
      // height: 18px;
      font-family: Microsoft YaHei UI, Microsoft YaHei UI;
      font-weight: 400;
      font-size: 18px;
      color: #ffffff;
      // line-height: 18px;
      text-align: left;
      font-style: normal;
      text-transform: none;
    }
  }
}
</style>