<template>
  <div class="home">
    <div class="homeTop">
      <div class="homeTop_img">
        <img src="../assets/image/Quanzhangwologo.png" alt="" />
      </div>
      <div class="homeTop_list">
        <div
          v-for="item in list"
          :key="item.index"
          @click="NameClick(item.id, item.index)"
        >
          <div :class="item.isBlue ? 'isBlue' : ''">{{ item.name }}</div>
        </div>
      </div>
      <div class="homeTop_btn">
        <!-- <a-button type="primary" @click="GoToLoginIn">登录 </a-button> -->
      </div>
    </div>
    <div class="top_BGC_img" ref="top_BGC_img">
      <div class="top_BGC_img0">由通信、多媒体、AI技术专家共同组建</div>
      <div class="top_BGC_img1">AI赋能装修信息化</div>
    </div>
    <!-- 公司简介 -->
    <div id="CompanyProfile" ref="CompanyProfile">
      <CompanyProfile />
    </div>
    <!-- 产品介绍 -->
    <div id="IntroductionProducts" ref="IntroductionProducts">
      <IntroductionProducts />
    </div>
    <!-- 技术服务 -->
    <div id="TechnicalServices" ref="TechnicalServices">
      <TechnicalServices />
    </div>
    <!-- 申请使用 -->
    <div id="ApplyTtrial" ref="ApplyTtrial">
      <ApplyTtrial />
    </div>
    <!-- 联系我们 -->
    <div id="ContactUs" ref="ContactUs">
      <ContactUs />
    </div>
    <div>
      <div class="home_bottom">
        <div class="homeTop_img">
          <img src="../assets/image/Quanzhangwologo.png" alt="" />
        </div>
        <div class="homeTop_list">
          <div
            v-for="item in list"
            :key="item.index"
            @click="NameClick(item.id, item.index)"
          >
            <a-popover placement="bottom" v-if="item.desc">
              <template slot="content">
                <p v-for="(el, index) in item.desc" :key="index">
                  {{ el.name }}
                </p>
              </template>
              <!-- <template slot="title">
                <span>Title</span>
              </template> -->
              <div :class="item.isBlue ? 'isBlue' : ''">{{ item.name }}</div>
            </a-popover>

            <div v-else :class="item.isBlue ? 'isBlue' : ''">
              {{ item.name }}
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import CompanyProfile from "./CompanyProfile.vue";
import IntroductionProducts from "./IntroductionProducts.vue";
import TechnicalServices from "./TechnicalServices.vue";
import ApplyTtrial from "./ApplyTtrial.vue";
import ContactUs from "./ContactUs.vue";
export default {
  name: "HomeView",
  components: {
    CompanyProfile,
    IntroductionProducts,
    TechnicalServices,
    ApplyTtrial,
    ContactUs,
  },
  data() {
    return {
      list: [
        {
          index: 0,
          id: "CompanyProfile",
          name: "公司简介",
          isBlue: true,
        },
        {
          index: 1,
          id: "IntroductionProducts",
          name: "产品介绍",
          desc: [
            { id: 1 - 0, name: "产品简介" },
            { id: 1 - 1, name: "产品构成" },
            { id: 1 - 2, name: "产品功能" },
            { id: 1 - 3, name: "产品价值" },
          ],
          isBlue: false,
        },
        {
          index: 2,
          id: "TechnicalServices",
          name: "技术服务",
          isBlue: false,
        },
        {
          index: 3,
          id: "ApplyTtrial",
          name: "申请试用",
          isBlue: false,
        },
        {
          index: 4,
          id: "ContactUs",
          name: "联系我们",
          isBlue: false,
        },
      ],
      scrollY: "",
    };
  },
  mounted() {
    window.addEventListener("scroll", this.handleScroll, true);
    // let CompanyProfile = this.$el.querySelector("#CompanyProfile").offsetTop;
    // let IntroductionProducts = this.$el.querySelector(
    //   "#IntroductionProducts"
    // ).offsetTop;
    // let TechnicalServices =
    //   this.$el.querySelector("#TechnicalServices").offsetTop;
    // let ApplyTtrial = this.$el.querySelector("#ApplyTtrial").offsetTop;
    // let ContactUs = this.$el.querySelector("#ContactUs").offsetTop;

    // console.log(
    //   CompanyProfile,
    //   IntroductionProducts,
    //   TechnicalServices,
    //   ApplyTtrial,
    //   ContactUs
    // );
  },
  methods: {
    NameClick(item, index) {
      // console.log(item);
      // 快速跳转
      this.$el.querySelector("#" + item).scrollIntoView({ behavior: "smooth" });
      // 平滑上移  { behavior: "smooth" }
      this.list.forEach((el) => {
        if (el.index == index) {
          el.isBlue = true;
          // console.log("===", el);
        } else {
          el.isBlue = false;
        }
      });
    },
    handleScroll() {
      // 滚动事件 发生后 拿到当前滚动的距离
      this.scrollY = document.documentElement.scrollTop + 40;
      // console.log(this.scrollY);
      if (this.scrollY > 736 && this.scrollY < 1651) {
        this.list[0].isBlue = true;
        this.list[1].isBlue = false;
        this.list[2].isBlue = false;
        this.list[3].isBlue = false;
        this.list[4].isBlue = false;
      } else if (this.scrollY > 1651 && this.scrollY < 7691) {
        this.list[0].isBlue = false;
        this.list[1].isBlue = true;
        this.list[2].isBlue = false;
        this.list[3].isBlue = false;
        this.list[4].isBlue = false;
      } else if (this.scrollY > 7691 && this.scrollY < 9025) {
        this.list[0].isBlue = false;
        this.list[1].isBlue = false;
        this.list[2].isBlue = true;
        this.list[3].isBlue = false;
        this.list[4].isBlue = false;
      } else if (this.scrollY > 8949 && this.scrollY < 9147) {
        this.list[0].isBlue = false;
        this.list[1].isBlue = false;
        this.list[2].isBlue = false;
        this.list[3].isBlue = true;
        this.list[4].isBlue = false;
      } else if (this.scrollY > 9147) {
        this.list[0].isBlue = false;
        this.list[1].isBlue = false;
        this.list[2].isBlue = false;
        this.list[3].isBlue = false;
        this.list[4].isBlue = true;
      }
    },
    GoToLoginIn() {
      this.$router.push({
        path: "/LoginIn",
      });
    },
  },
};
</script>
<style scoped lang="less">
.home {
  // margin: 0 50px;
  min-width: 1180px;
}
.homeTop {
  height: 83px;
  width: 100%;
  background-color: #ffffff;
  display: flex;
  justify-content: space-around;
  align-items: center;
  position: fixed;
  top: 0;
  z-index: 999;
  .homeTop_img {
    img {
      width: 117px;
      height: 61px;
    }
  }

  .homeTop_list {
    display: flex;
    div {
      width: 117px;
      height: 83px;
      line-height: 83px;
      // background: #2164d8;
      border-radius: 0px 0px 0px 0px;
      font-family: Microsoft YaHei UI, Microsoft YaHei UI;
      font-weight: 400;
      font-size: 20px;
      color: #000000;
      font-style: normal;
      text-transform: none;
      cursor: pointer;
    }
    div:hover {
      // color: white;
      // background-color: #2164d8;
    }
  }
}
.top_BGC_img {
  margin-top: 83px;
  width: 100%;
  height: 653px;
  background-image: url("../assets/image/Mask group0.png");
  .top_BGC_img0 {
    width: 484px;
    height: 36px;
    font-family: Microsoft YaHei UI, Microsoft YaHei UI;
    font-weight: 400;
    font-size: 28px;
    color: #ffffff;
    line-height: 33px;
    text-align: left;
    font-style: normal;
    text-transform: none;
    padding-top: 252px;
    margin-left: 20%;
  }
  .top_BGC_img1 {
    width: 501px;
    height: 76px;
    font-family: Microsoft YaHei UI, Microsoft YaHei UI;
    font-weight: bold;
    font-size: 60px;
    color: #ffffff;
    line-height: 70px;
    text-align: left;
    font-style: normal;
    text-transform: none;
    // padding-top: 252px;
    margin-top: 80px;
    margin-left: 20%;
  }
}
#CompanyProfile,
#IntroductionProducts,
#TechnicalServices,
#ApplyTtrial,
#ContactUs {
  // width: 50%;
  // height: 400px;
  // background-color: pink;
  text-align: center;
  padding-top: 83px;
}

.home_bottom {
  // background-color: pink;
  height: 320px;
  display: flex;
  justify-content: center;
  padding-top: 50px;
  .homeTop_img {
    img {
      width: 117px;
      height: 61px;
    }
  }
  .homeTop_list {
    display: flex;
    div {
      width: 117px;
      height: 83px;
      line-height: 83px;
      // background: #2164d8;

      font-family: Microsoft YaHei UI, Microsoft YaHei UI;
      font-weight: 400;
      font-size: 20px;
      color: #000000;
      font-style: normal;
      text-transform: none;
    }
    div:hover {
      color: white;
      background-color: #2164d8;
    }
  }
}
.isBlue {
  color: white !important;
  background-color: #2164d8;
}
</style>
