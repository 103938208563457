/**
 * 按需引入ant design的组件，使用到的UI组件都要在此定义
 * 定义后的组件可以在全局使用，无需再次引入
 */
import Vue from "vue";

import {
  ConfigProvider,
  Alert,
  Button,
  Input,
  Form,
  FormModel,
  Menu,
  Popover,
} from "ant-design-vue";
Vue.use(ConfigProvider);
Vue.use(Alert);
Vue.use(Button);
Vue.use(Input);
Vue.use(Form);
Vue.use(FormModel);
Vue.use(Menu);
Vue.use(Popover);
