<template>
  <div class="ApplyTtrial">
    <div class="title">
      <div class="title_inner">申请试用</div>
    </div>
  </div>
</template>

<script>
export default {
  name: "ApplyTtrial",
  //   申请试用
  data() {
    return {};
  },
  created() {
    // let a = "123456789";
    // console.log(a.slice(0, 4));
    // console.log(a.slice(-4));
    // console.log(a.split("3"));
    // let b = [1, 2, 3, 4, 5, 6];
    // console.log(b.splice(2, 3));
  },
};
</script>

<style scoped lang="less">
.ApplyTtrial {
  width: 100%;
  //   height: 500px;
  text-align: center;
}
.title {
  width: 100%;
  display: flex;
  justify-content: center;
}
.title_inner {
  width: 322px;
  height: 116px;
  line-height: 116px;
  text-align: center;
  border-radius: 8px 8px 8px 8px;
  border: 2px solid #111111;
  font-family: Microsoft YaHei UI, Microsoft YaHei UI;
  font-weight: 400;
  font-size: 48px;
  color: #000000;
  font-style: normal;
  text-transform: none;
}
</style>