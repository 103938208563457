<template>
  <div class="IntroductionProducts">
    <div class="title">产品简介</div>
    <div class="DIsFlex">
      <div class="flex_inner desc1">
        <div class="flex_inner_tit">一、AI可视化工地管理系统</div>
        <div class="flex_inner_desc">
          <div>
            综合了人员、工地、设备等多维度管理，Al助力实时监管，工地全程直播，轻松管理所有装修项目
          </div>
        </div>
      </div>
      <div class="flex_inner desc2">
        <div class="flex_inner_tit">二、小程序</div>
        <div class="flex_inner_desc">
          <div>
            微信小程序囊括了业主、工人、管家三个端口，分别
            提供远程监控、抢单接单、远程管理等主要服务功能
          </div>
        </div>
      </div>
    </div>
    <div class="CPGC_title">产品构成</div>
    <div>
      <div class="CPGC_flex">
        <img src="../assets/image/iPhone 13 mini - 65 1.png" alt="" />
        <div class="flex">
          <div>--------</div>
          <div class="sanjiaoxing"></div>
        </div>
        <div class="CPGC_flex_desc">
          <div>客户端口</div>
          <div>随时看进度</div>
          <div>(微信小程序)</div>
        </div>
      </div>
      <div class="CPGC_flex">
        <div class="CPGC_flex_desc">
          <div>施工现场</div>
          <div>远程监管</div>
          <div>(微信小程序)</div>
        </div>
        <div class="flex">
          <div class="sanjiaoxing_right"></div>
          <div>--------</div>
        </div>
        <div class="position_r">
          <div class="img_pos">
            <img src="../assets/image/image 39.png" alt="" />
          </div>
          <img src="../assets/image/iPhone 13 mini - 66 1.png" alt="" />
        </div>
      </div>
      <!-- 网页管理后台 -->
      <div class="CPGC_flex GLHT">
        <div class="position_r">
          <div class="img_pos">
            <img src="../assets/image/image 23.png" alt="" />
          </div>
          <img src="../assets/image/image 22.png" alt="" />
        </div>
        <div class="flex">
          <div>-----</div>
          <div class="sanjiaoxing"></div>
        </div>
        <div class="CPGC_flex_desc">
          <div>管理人员</div>
          <div>(网页管理后台)</div>
        </div>
      </div>
      <!-- 工人端 -->
      <div class="CPGC_flex">
        <div class="CPGC_flex_desc">
          <div>工人端</div>
          <div>线上抢/接单</div>
          <div>(微信小程序)</div>
        </div>
        <div class="flex">
          <div class="sanjiaoxing_right"></div>
          <div>--------</div>
        </div>
        <img src="../assets/image/iPhone_hua.png" alt="" />
      </div>
    </div>
    <!--产品功能  -->
    <div class="CPGN">
      <div class="CPGN_title">产品功能</div>
      <div class="CPGN_inner">
        <div class="Vector47_img">
          <img src="../assets/image/Introduct/Vector 47.png" alt="" />
        </div>
        <div class="flex4">
          <div class="BGC_img">工地形象管理</div>
          <div class="BGC_img">工地管理</div>
          <div class="BGC_img">人员管理</div>
          <div class="BGC_img">设备管理</div>
        </div>
        <div class="flex4 flex3">
          <div class="BGC_img">工地形象管理</div>
          <div class="BGC_img">工地管理</div>
          <div class="BGC_img">人员管理</div>
        </div>
        <div class="flex4 flex2">
          <div class="BGC_img">业主查看工地</div>
          <div class="BGC_img">案场推荐展示</div>
        </div>
        <div class="flex4 flex1">
          <div class="BGC_img">数据看板</div>
        </div>
        <div class="Vector48_img">
          <img src="../assets/image/Introduct/Vector 48.png" alt="" />
        </div>
      </div>
    </div>
    <!--产品价值  -->
    <div class="CPJZ">
      <div class="CPJZ_title">产品价值</div>
      <div class="flex_div_3">
        <!-- 客户角度 -->
        <div class="little_div">
          <div class="little_div_tit">
            <div class="BlueDiv"></div>
            客户角度
          </div>
          <div class="little_div_dec">
            <div>·提升体验感</div>
            <div>·助力获客</div>
            <div>·隐蔽工程让业主放心</div>
          </div>
        </div>
        <!-- 工人角度 -->
        <div class="little_div">
          <div class="little_div_tit">
            <div class="BlueDiv"></div>
            工人角度
          </div>
          <div class="little_div_dec">
            <div>·促进工人遵章守纪</div>
            <div>·不是监控而是提醒</div>
            <div>·系统客观评价工人促进优胜劣汰</div>
          </div>
        </div>
        <!-- 工地角度 -->
        <div class="little_div mar_r0">
          <div class="little_div_tit">
            <div class="BlueDiv"></div>
            工地角度
          </div>
          <div class="little_div_dec">
            <div>·工地形象持续改进</div>
            <div>·客观评估实际用工量</div>
            <div>·总结都谁来过工地</div>
          </div>
        </div>
      </div>
      <div class="flex_div_3">
        <!-- 管家角度 -->
        <div class="little_div">
          <div class="little_div_tit">
            <div class="BlueDiv"></div>
            管家角度
          </div>
          <div class="little_div_dec">
            <div>·轻松远程巡查工地</div>
            <div>·工地问题及时提醒</div>
            <div>·施工工料矛盾及时预警</div>
          </div>
        </div>
        <!-- 降本增效 -->
        <div class="little_div">
          <div class="little_div_tit">
            <div class="BlueDiv"></div>
            降本增效
          </div>
          <div class="little_div_dec">
            <div>·用更少的管家管更多的工地</div>
            <div>·减少监管人员提高监管效果</div>
            <div>·信息自动采集减少人员投入</div>
          </div>
        </div>
        <!-- 综合管理 -->
        <div class="little_div mar_r0">
          <div class="little_div_tit">
            <div class="BlueDiv"></div>
            综合管理
          </div>
          <div class="little_div_dec">
            <div>·制度落地有抓手数据更客观</div>
            <div>·图形可视化让管理更直观</div>
            <div>·日报订阅让管理更轻松</div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "IntroductionProducts",
  data() {
    return {};
  },
};
</script>

<style scoped lang="less">
.IntroductionProducts {
  width: 100%;
  height: auto;
  //   text-align: center;
  margin-top: 30px;
}
.title {
  background: #061723;
  //   width: 192px;
  text-align: center;
  //   height: 61px;
  font-family: Microsoft YaHei UI, Microsoft YaHei UI;
  font-weight: bold;
  font-size: 48px;
  color: #ffffff;
  //   line-height: 56px;
  line-height: 150px;
  //   text-align: left;
  font-style: normal;
  text-transform: none;
}
.DIsFlex {
  background: #061723;
  display: flex;
  justify-content: space-around;
  height: 450px;
  width: 100%;
  .flex_inner {
    // width: 910px;
    width: 45%;
    height: 339px;

    // width: 474px;
    font-weight: 400;
    font-size: 20px;
    color: #01c1bc;
    padding: 60px 0;

    .flex_inner_tit {
      margin: 20px 0;
    }
    .flex_inner_desc {
      //   width: 474px;
      width: 100%;
      height: 75px;
      display: flex;
      justify-content: center;

      div {
        width: 70%;
        font-family: Microsoft YaHei UI, Microsoft YaHei UI;
        font-weight: 400;
        font-size: 16px;
        color: #01c1bc;
        line-height: 30px;
        text-align: left;
        font-style: normal;
        text-transform: none;
      }
    }
  }
  .desc1,
  .desc2 {
    margin-top: 50px;
    height: 300px;
    background-size: 100% 100%;
    // border: 6px solid #11686e;
    // position: relative;
    // clip-path: polygon(50% 10, 50% 50%, 50% 50%, 0 50%);
  }
  .desc1 {
    background-image: url("../assets/image/Subtract1.png");
  }
  .desc2 {
    background-image: url("../assets/image/Subtract.png");
  }
  .flex_inner:hover {
    // background: #03373f;
  }
}
.CPGC_title {
  // width: 192px;
  // height: 61px;
  font-family: Microsoft YaHei UI, Microsoft YaHei UI;
  font-weight: bold;
  font-size: 48px;
  color: #000000;
  line-height: 56px;
  //   text-align: left;
  font-style: normal;
  text-transform: none;
  text-align: center;
  margin-top: 10px;
}
.CPGC_flex {
  margin-top: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: Microsoft YaHei UI, Microsoft YaHei UI;
  font-weight: 400;
  font-size: 40px;
  color: #000000;
  line-height: 47px;
  text-align: left;
  font-style: normal;
  text-transform: none;
  img {
    margin: 0 10px;
  }
  .sanjiaoxing {
    width: 0;
    height: 0;
    border-top: 15px solid transparent;
    border-right: 15px solid #2059ea;
    border-left: 15px solid transparent;
    border-bottom: 15px solid transparent;
    margin-top: 5px;
  }
  .flex {
    display: flex;
    align-items: center;
    color: #2059ea;
  }
}
.CPGC_flex_desc {
  width: 227px;
  height: 153px;
  margin: 0 10px;
  //   background-color: red;
}
.sanjiaoxing_right {
  width: 0;
  height: 0;
  border-top: 15px solid transparent;
  border-right: 15px solid transparent;
  border-left: 15px solid #2059ea;
  border-bottom: 15px solid transparent;
  margin-top: 5px;
}
.position_r {
  position: relative;
  margin-left: 170px;
  .img_pos {
    position: absolute;
    left: -180px;
    top: 150px;
  }
}
.GLHT {
  height: 400px;
  margin-bottom: 150px;
  .position_r {
    margin-right: 50px;
  }
  .img_pos {
    left: 30px;
    top: 180px;
  }
  .CPGC_flex_desc {
    width: 280px;
  }
}
.CPGN {
  margin-top: 50px;
  width: 100%;
  background-color: #0a0e27;
  text-align: center;
  height: 1300px;
  .CPGN_title {
    // width: 192px;
    // height: 61px;
    font-family: Microsoft YaHei UI, Microsoft YaHei UI;
    font-weight: bold;
    font-size: 48px;
    color: #ffffff;
    line-height: 150px;
    // text-align: left;
    font-style: normal;
    text-transform: none;
  }
  .CPGN_inner {
    width: 90%;
    margin: 0 5%;
    position: relative;
    .Vector47_img {
      //   text-align: left;

      position: absolute;
    }
    .Vector48_img {
      right: 0;
      position: absolute;
      bottom: -50px;
    }
    .BGC_img {
      background-image: url("../assets/image/Introduct/Polygon 13.png");
      width: 204px;
      height: 235px;
      font-family: Microsoft YaHei UI, Microsoft YaHei UI;
      font-weight: bold;
      font-size: 20px;
      color: #5cffe2;
      line-height: 235px;
      text-align: center;
      font-style: normal;
      text-transform: none;
    }
    .flex4 {
      display: flex;
      justify-content: space-around;
      width: 90%;
      margin-left: 5%;
      padding-top: 50px;
    }
    .flex3 {
      width: 70%;
      margin-left: 15%;
      margin-top: -50px;
    }
    .flex2 {
      width: 50%;
      margin-left: 25%;
      margin-top: -50px;
    }
    .flex1 {
      width: 50%;
      margin-left: 25%;
      margin-top: -50px;
    }
  }
}
.CPJZ {
  margin-top: 50px;
  width: 100%;
  text-align: center;
  .CPJZ_title {
    font-family: Microsoft YaHei UI, Microsoft YaHei UI;
    font-weight: bold;
    font-size: 48px;
    color: #000000;
    line-height: 56px;
    // text-align: left;
    font-style: normal;
    text-transform: none;
  }
  .flex_div_3 {
    display: flex;
    justify-content: center;
    .BlueDiv {
      width: 10px;
      height: 24px;
      background: #2164d8;
      margin-top: 6px;
      margin-right: 10px;
    }
    .little_div {
      text-align: left;
      width: 331px;
      height: 306px;
      background: #ffffff;
      box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.1);
      border-radius: 10px;
      padding-left: 30px;
      padding-top: 30px;
      margin-right: 10px;
      margin-top: 50px;

      .little_div_tit {
        //   width: 96px;
        height: 50px;
        font-family: Microsoft YaHei UI, Microsoft YaHei UI;
        font-weight: 400;
        font-size: 24px;
        color: #000000;
        font-style: normal;
        text-transform: none;
        display: flex;
      }
      .little_div_dec {
        line-height: 50px;
        font-size: 18px;
        font-weight: 400;
      }
    }
    .mar_r0 {
      margin-right: 0;
    }
  }
}
</style>